@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/* Fix use of <Link /> and linked route not loading with scroll position at top of the page */
	html,
	body,
	#__next {
		min-height: 100%;
		max-width: 100vw;
		overflow-x: hidden;
		background-color: black;
	}

	html {
		background-color: rgb(30 30 30);
	}

	button,
	textarea,
	input,
	select,
	a {
		-webkit-tap-highlight-color: rgba(230, 230, 230, 0.5);
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	::-moz-selection {
		color: white;
		background: rgb(30 30 30);
	}

	::selection {
		color: white;
		background: rgb(30 30 30);
	}

	/* Prevent IOS zooming inputs */
	input,
	textarea {
		font-size: 16px !important;
	}
}

@layer components {
	.scroll-to-top {
		border: 1px solid white !important;
	}

	.scroll-to-top-button {
		@apply bottom-8 right-8 flex h-auto w-auto items-center justify-center rounded-full bg-white/50 p-3 shadow-none backdrop-blur transition-all duration-300 ease-in-out hover:scale-105 hover:bg-gray-100 !important;
	}

	mark.highlight-yellow-neon {
		@apply bg-yellow-neon/80 p-1;
	}

	mark.highlight-yellow-neon-text {
		@apply bg-yellow-neon/20 p-1 text-yellow-neon;
	}
}

@layer utilities {
	.paused {
		animation-play-state: paused;
	}
}

.bg-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
}

.bg-texture {
	background-image: url('/backgrounds/bg-texture.webp');
	background-repeat: repeat;
}

.bg-jujitsu {
	background-image: url('/vectors/jujitsu-pattern.svg');
	background-repeat: repeat;
}
